'use client'
import React from 'react'
import Image from 'next/image'
import { type Image as ImageType } from '@api/types'

interface CardProps {
  description: string
  icon: ImageType
  id: string
  title: string
}

const Card: React.FC<CardProps> = ({ title, description, icon, id }) => {
  const image = icon?.formats?.thumbnail

  return (
    <div className='card bg-primary shadow-xl'>
      <div className='px-10 pt-10'>
        <Image
          src={
            image?.url ??
            `https://res.cloudinary.com/${process.env.NEXT_PUBLIC_CLOUDINARY_NAME}/image/upload/v1710241970/thumbnail_icon${id}.png`
          }
          alt={image?.name ?? ''}
          width={50}
          height={50}
        />
      </div>
      <div className='card-body items-start text-white gap-3 p-7'>
        <h3 className='card-title text-base'>{title}</h3>
        <p className='font-normal'>{description}</p>
      </div>
    </div>
  )
}

export default Card
