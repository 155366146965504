'use client'
import { useRef } from 'react'
import Link from 'next/link'
import { ParsedSectionData } from '@api/getSections'

interface MenuProps {
  children: React.ReactNode
  data: ParsedSectionData[]
}

const Menu: React.FC<MenuProps> = ({ children, data }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (): void => {
    inputRef.current?.click()
  }

  return (
    <div className='drawer drawer-end'>
      <input ref={inputRef} id='menu' type='checkbox' className='drawer-toggle' />
      <div className='drawer-content'>
        {children}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor='menu'
          className='drawer-button btn btn-circle fixed left-4 top-4 bg-primary border-none text-white shadow-xl'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5' />
          </svg>
        </label>
      </div>
      <div className='drawer-side'>
        <label htmlFor='menu' aria-label='close sidebar' className='drawer-overlay'></label>
        <ul className='menu p-4 w-80 min-h-full bg-primary text-white'>
          <li>
            <button
              onClick={handleChange}
              type='button'
              className='btn btn-circle bg-transparent border-none text-white self-end'
              aria-label='close menu'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M6 18 18 6M6 6l12 12' />
              </svg>
            </button>
          </li>
          {data.map((section) => (
            <li key={section.id}>
              <Link onClick={handleChange} href={`#${section.path}`}>
                {section.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Menu
